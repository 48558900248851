import BaseComponent from './BaseComponent.js'

class Video extends BaseComponent {

    constructor(options) {
        super(options)

        this.ui.video = this.el.querySelector('.js-video-video')
        this.ui.iframe = this.el.querySelector('.js-video-iframe')
        this.ui.poster = this.el.querySelector('.js-video-poster')

        this.onClick = this.onClick.bind(this)
        this.setIframeHeight = this.setIframeHeight.bind(this)

        this.initialized()
    }

    initialized() {
        this.setIframeHeight()
        this.setupEventListeners()
    }

    setIframeHeight() {
        if (this.ui.iframe) {
            const iframeWidth = this.ui.iframe.getBoundingClientRect().width
            this.ui.iframe.parentElement.style.height = iframeWidth * (9/16) + 'px'
        }
    }

    setupEventListeners() {
        if (this.ui.poster) {
            this.ui.poster.addEventListener('click', this.onClick)
        }

        if (this.ui.iframe) {
            window.addEventListener('resize', this.setIframeHeight)
        }
    }

    onClick(e) {
        if (this.ui.poster) {
            this.ui.poster.classList.toggle('hide')
        }

        if (this.ui.video) {
            this.ui.video.play()
        } 

        if (this.ui.iframe) {
    
            const lastCharAmpersand = this.ui.iframe.src[this.ui.iframe.src.length - 1] === '&'
            const hasEmptyParams = this.ui.iframe.src.includes('?&') && this.ui.iframe.src.slice(-2) == '?&'

            if (hasEmptyParams) {
                this.ui.iframe.src = this.ui.iframe.src.slice(0, -2)
            }

            const hasParams = this.ui.iframe.src.includes('?')
        
            if (hasParams) {
                if (lastCharAmpersand) {
                    this.ui.iframe.src += 'autoplay=1'
                } else {
                    this.ui.iframe.src += '&autoplay=1'
                }
            } else {
                this.ui.iframe.src += '?autoplay=1'
            }
    
                // console.log(this.ui.iframe.src)
    
            }

        e.currentTarget.removeEventListener('click', this.onClick)
    }

}

module.exports = Video