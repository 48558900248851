import EventEmitter from './EventEmitter';

class ResizeManager extends EventEmitter {
 
	constructor() {
		super();

		this.BREAKPOINTS = {
			small: '(max-width:767px)',
			medium: '(min-width:768px) and (max-width: 1023px)',
            large: '(min-width:1024px) and (max-width: 1199px)',
            xLarge: '(min-width:1200px)'
		};

		this.EVENTS = {
			resize: 'ResizeManager::resize',
			breakpoint: 'ResizeManager::breakpoint'
		};

		this.viewport = {
			width: 2000,
			height: 2000
		};

		this.resizeHandler = this.resizeHandler.bind(this);
		this._emitResizeComplete = this._emitResizeComplete.bind(this);
		
		this.initialized();
		
        setTimeout(() => {

			if (typeof Event === 'function') {

				window.dispatchEvent(new Event('resize'));

			} else {	//IE fallback

				const event = document.createEvent('Event');
				event.initEvent('resize', true, false, window, 0);
				window.dispatchEvent(event);

			}

        }, 0);
	}

	initialized() {
		this.setupEventListeners();
	}

	setupEventListeners() {
		window.addEventListener('resize', this.resizeHandler);
	}

	getViewport() {
		return this.viewport || { width: window.innerWidth, height: window.innerHeight };
	}

	getBreakpoint() {
		return this.currentBreakpoint;
	}
    
    /*isMobile() {
        return ['small', 'medium'].includes(this.getBreakpoint());
    }*/

	_emitResize(completed = false) {
	 
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;
		
		this.viewport = {
			width: viewportWidth,
			height: viewportHeight
		};
		
		this._checkBreakpoints();
		
		this.dispatchCustomEvent(this.EVENTS.resize, {
			width: viewportWidth,
			height: viewportHeight,
			completed
		});
	}

	_emitResizeComplete() {
	 
		this._emitResize(true);
	}

	_emitBreakpointChange(breakpoint) {
	    
		this.dispatchCustomEvent(this.EVENTS.breakpoint, {
			breakpoint
		});
	}
	
	_checkBreakpoints() {
	 
		const { matchMedia } = window;
		let minWidth = 0;
		let lastBreakpoint = null;
		
		for (const breakpoint in this.BREAKPOINTS) {
		 
			if (this.currentBreakpoint !== breakpoint && matchMedia(this.BREAKPOINTS[breakpoint]).matches) {
			 
				this.currentBreakpoint = breakpoint;
				this._emitBreakpointChange(breakpoint);
				break;
				
			}
			
			minWidth = this.BREAKPOINTS[breakpoint];
			lastBreakpoint = breakpoint;
			
		}
		
		if (!this.currentBreakpoint) {
		 
			this.currentBreakpoint = this.BREAKPOINTS[lastBreakpoint];
			this._emitBreakpointChange(lastBreakpoint);
		}
	}

	resizeHandler() {

		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.timer = setTimeout(this._emitResizeComplete, 500);
		this._emitResize();
	}

}

module.exports = new ResizeManager();