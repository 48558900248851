import BaseComponent from './BaseComponent.js'

class ParallelScroll extends BaseComponent {

    constructor(options) {
        super(options)

        this.ui.projects = this.el.querySelectorAll('.js-project')

        this.initialized()
    }

    initialized() {
        this.setupObserver()
    }

    setupObserver() {
        if (this.ui.projects) {
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

            this.ui.projects.forEach((project, i) => {
                // if (i === 0) { // TODO remove (used for test purposes)
                    // const rootMargin = (vh / 2) - (project.getBoundingClientRect().height)
                    const rootMargin = (vh / 2) - (project.getBoundingClientRect().height / 2)
                    const options = {
                        root: null,
                        threshold: buildThresholdList(),
                        // rootMargin: `${rootMargin}px 0px`
                        rootMargin: `-500px 0px 1000px 0px`
                    }

                    project.classList.add('hasParallex')
                    project.style.backgroundPositionY = '10%'

                    const observer = new IntersectionObserver(handleIntersect, options)
                    observer.observe(project)
                // }
            })
        }
    }
}

function handleIntersect(entries, observer) {
    entries.forEach(entry => {

        // window.getComputedStyle(entry.target).backgroundPositionY


        const offset = (entry.boundingClientRect.height / 10) * entry.intersectionRatio 
        entry.target.style.backgroundPositionY = `-${offset}%`



        // projectImage.style.opacity = `${entry.intersectionRatio}`
    })
}

function buildThresholdList() {
    const thresholds = []
    const numSteps = 20
  
    for (let i = 1.0; i <= numSteps; i++) {
      const ratio = i / numSteps
      thresholds.push(ratio)
    }
  
    return thresholds
}

module.exports = ParallelScroll