require('../scss/index.scss');
require('./polyfills');
require('./polyfills/flickityFix');
import AppStore from './helpers/AppStore';

if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}

const components = document.querySelectorAll('[data-component]');
const sliders = [];

var classNames = [];
if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
if (navigator.userAgent.match(/android/i)) classNames.push('device-android');

var html = document.getElementsByTagName('html')[0];

if (classNames.length) classNames.push('on-device');
if (html.classList) html.classList.add.apply(html.classList, classNames);

/*
 * Scan document for components and require them if needed
 */
components.forEach((componentElement) => {
    const componentName = componentElement.getAttribute('data-component');
    const Component = require(`./components/${componentName}`);

    const component = new Component({
        el: componentElement,
        store: AppStore,
    });

    if (componentName === 'SliderComponent') {
        sliders.push(component);
    }
});

/*
 * Check if touch screen
 */
window.addEventListener(
    'touchstart',
    function onFirstTouch() {
        document.documentElement.classList.remove('no-touchevents');
        document.documentElement.classList.add('touchevents');

        /* sliders.forEach(slider => {

		slider.resize();

	}); */
    }.bind(this), {
        once: true
    }
);

const fontSize = AppStore.getKey('fontSize') || 'medium';
document.body.classList.add(fontSize);