class maskedSlider{
    constructor(options){

        this.options = options
        this.slidables = this.options.el.querySelectorAll('.image-container')
        this.slidableStatus = []
        this.initialSlideDuration = 3000 // this should be this.iteratedSlideDuration - duration of transition (see the _masked-slider.scss fiel(s)css file). e.g. 7500 - 4500 = 3000
        this.iteratedSlideDuration = 7500

        this.init()
    }

    init(){
        
        this.slidables.forEach((element,i) => {

            if(element.classList.contains('is-active')){
                this.slidableStatus.push('is-active')
            } else if(element.classList.contains('is-prev')){
                this.slidableStatus.push('is-prev')
            }else if(element.classList.contains('is-next')){
                this.slidableStatus.push('is-next')
            } else{
                this.slidableStatus.push('')
            }

        });

        setTimeout(() => {
            this.toggleState()
            this.shiftArray(this.slidableStatus)
            this.toggleState()

            setInterval(
                ()=>{
                    this.toggleState()
                    this.shiftArray(this.slidableStatus)
                    this.toggleState()
                },
                this.iteratedSlideDuration
            )
        }, this.initialSlideDuration)
    }

    shiftArray(array){
        array.unshift(array.pop())
        // console.log(array)
    }

    toggleState(){
        this.slidables[this.slidableStatus.indexOf('is-active')].classList.toggle('is-active')
        this.slidables[this.slidableStatus.indexOf('is-prev')].classList.toggle('is-prev')
        this.slidables[this.slidableStatus.indexOf('is-next')].classList.toggle('is-next')
    }

}

module.exports = maskedSlider