import Glide from '@glidejs/glide'
import { toPairs } from 'lodash'
class clientSlider{
    constructor(options){

        this.options = options
        this.init()   
    }

    init(){
        this.className = ''
        this.options.el.classList.forEach(str =>{
            this.className += `.${str}`
        })

        this.slider = new Glide(this.className,{
            type: 'carousel',
            perView: 7,
            gap: 36,
            rewind: true,
            autoplay: 1500,
            hoverpause: true,
            startAt: this.options.el.getAttribute('data-offset'),
            breakpoints: {
                200: { perView: 1 },
                350: { perView: 3 },
                450: { perView: 4 },
                550: { perView: 4 },
                768: { perView: 5 },
                1000: { perView: 7 }
            }
        })
        
        this.options.el.querySelectorAll('.client__arrow').forEach(element =>{
            element.addEventListener('click',()=>{
                this.slider.update({
                    autoplay: false
                })
            })
        })

        this.slider.mount()
    }
}

module.exports = clientSlider