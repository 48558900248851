class mobileMenu{
    constructor(options){

        this.options = options
        this.menu = this.options.el
        this.menuButton = this.menu.querySelector('.header__menu-toggle')
        this.init()
    }

    init(){
        
        this.menuButton.addEventListener('click',()=>{
            document.body.classList.toggle('menu--open')
        })

        window.addEventListener('resize',()=>{
            window.innerWidth > 1049 && document.body.classList.remove('menu--open')
        })
        
    }

}

module.exports = mobileMenu