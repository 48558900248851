import BaseComponent from './BaseComponent.js'

class ParallelScroll extends BaseComponent {

    constructor(options) {
        super(options)

        this.ui.projects = this.el.querySelectorAll('.js-parallax')

        this.initialized()
    }

    initialized() {
        this.ui.projects.forEach(el => {
            this.parallax(el)
        });
        window.addEventListener('scroll',(e)=>{
            this.ui.projects.forEach(el => {
                this.parallax(el)
            });
        })
    }

    parallax(el) {
        const scrollY = window.scrollY
        const scrollVariation = 30 // was 60
        const pos = el.getBoundingClientRect() 
        if(pos.top > 0 || pos.bottom > 0){
            el.querySelector('img').style.transform = `scale(1.2) translate3d(0,${(pos.top/(window.innerHeight))*scrollVariation}px,0)` // scale(1.2) was scale(1.4)
        }
    }
}

module.exports = ParallelScroll