import ScrollManager from '../helpers/ScrollManager';
import ResizeManager from '../helpers/ResizeManager';

import AppStore from '../helpers/AppStore';

class BaseComponent {

	constructor(options) {

        this.el = options.el;
        this.store = options.store;

        this.STATE_KEYS = [];

        this.ui = this.ui || {};

        this.windowScrollHandler = this.windowScrollHandler.bind(this);
		this.windowResizeHandler = this.windowResizeHandler.bind(this);
		this.breakpointChangeHandler = this.breakpointChangeHandler.bind(this);
        this.updatePosition = this.updatePosition.bind(this);

        this.storeUpdateHandler = this.storeUpdateHandler.bind(this);

		this.viewport = { x:0, y: 0};
		this.breakpoint = '';
		this.offsetTop = 0;
		this.componentHeight = 0;
		this.scrollPosition = 0;
		this.rawProgress = 0;
		this.progress = 0;
		this.showOnProgress = .05;
		this.doAnimateIn = false;
		this.inView = false;

        this._setupEventListeners();
        
	}

	_setupEventListeners() {

		ScrollManager.addEventListener(ScrollManager.EVENTS.scroll, this.windowScrollHandler);
		ResizeManager.addEventListener(ResizeManager.EVENTS.resize, this.windowResizeHandler);
        ResizeManager.addEventListener(ResizeManager.EVENTS.breakpoint, this.breakpointChangeHandler);

        AppStore.addEventListener(AppStore.EVENTS.update, this.storeUpdateHandler);

    }
    
    storeUpdateHandler({ detail }) {

		const { key } = detail;
		if (this.STATE_KEYS.includes(key)) {
			this.onStoreUpdate && this.onStoreUpdate(detail);
		}
	}

	windowScrollHandler({ detail }) {

		this.scrollPosition = detail.y;
		this.updatePosition();

		this.onWindowScroll && this.onWindowScroll(detail);

	}

	windowResizeHandler({ detail }) {

		this.viewport = detail;
		this.offsetTop = this.el.getBoundingClientRect().top + (this.scrollPosition || 0) - this.viewport.height;
		this.componentHeight = this.el.offsetHeight;
		this.updatePosition();

		this.onWindowResize && this.onWindowResize(detail);
	}

	breakpointChangeHandler({ detail }) {

		this.breakpoint = detail.breakpoint;
		this.onBreakpointChange && this.onBreakpointChange(detail);
	}

	updatePosition() {

		this.rawProgress = ((this.scrollPosition || 0) - this.offsetTop) / (this.componentHeight + this.viewport.height);
		this.progress = Math.min(Math.max(this.rawProgress, 0), 1);

		const nextInView = this.progress > 0 && this.progress < 1;
        const nextAnimateIn = this.progress >= this.showOnProgress;
    

		if (nextInView !== this.inView) {

			if (!nextInView && this.progress <= 0) {

				this.el.classList.remove('in-view');

			}

			this.inView = nextInView;

		}

		if (nextAnimateIn !== this.doAnimateIn) {

			if (nextAnimateIn) {

				this.el.classList.add('in-view');

			}

			this.doAnimateIn = nextAnimateIn;

		}

	}

}

module.exports = BaseComponent;
