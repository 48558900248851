/*
 * Usage:
 *
 * Use the "set" method to update the state.
 * AppState.set()
 */
import EventEmitter from './EventEmitter';

class AppState extends EventEmitter {
	constructor() {
		super();

		// Debug setting
		this.DEBUG = false;

		// Set default storage key
		this.STORAGE_KEY = 'App';
		this.STORAGE = sessionStorage;
		this.STORAGE_PERSIST = localStorage;

		// Events used by class
		this.EVENTS = {
			update: 'state::update'
		};

		let state = this.get(null, true);
		this.STORAGE.setItem(this.STORAGE_KEY, JSON.stringify(state));

		if (this.DEBUG) {
			const maxCharacters = 3145728; // 3mb
			const storageLength = (this.STORAGE.getItem(this.STORAGE_KEY) || '').length;
			const persistStorageLength = (this.STORAGE_PERSIST.getItem(this.STORAGE_KEY) || '').length;
			console.groupCollapsed('Storage capacity');
			console.warn('session storage', `${storageLength}/${maxCharacters}`, `(${((storageLength / maxCharacters) * 100).toFixed(2)}%)`);
			console.warn('local storage', `${persistStorageLength}/${maxCharacters}`, `(${((persistStorageLength / maxCharacters) * 100).toFixed(2)}%)`);
			console.groupEnd();
		}
	}

	/*
	 * Parameters:
	 * key: @string 						- this string can contain '.' (dot). Each dot will create or edit a deeper level
	 * value: @string / @object / @array	- Make sure the data can be JSON stringified/parsed
	 * persist: @boolean					- Check if the data should be persistant, if so use the persistant storage
	 */
	set(key, value, persist) {
		if (this.isStorageAvailable()) {
			const currentState = this.get();
			const newState = this._setState(currentState, key, value);
			this.STORAGE.setItem(this.STORAGE_KEY, JSON.stringify(newState));

			if (persist) {
				const currentPersistState = this.get(null, true);
				const newPersistState = this._setState(currentPersistState, key, value);
				this.STORAGE_PERSIST.setItem(this.STORAGE_KEY, JSON.stringify(newPersistState));
			}

			// Trigger update event
			this._update(key, value);
		}
	}

	get(key, persist) {

		if (this.isStorageAvailable()) {
			const storage = persist ? this.STORAGE_PERSIST : this.STORAGE;
			const stateJson = storage.getItem(this.STORAGE_KEY) || '{}';
            const state = JSON.parse(stateJson);

			if (!key) {
				return state || {};
			} else {
				let value = state;
				key.split('.').forEach(_key => {
					if (value) {
						value = value[_key];
					}
				});
				return value;
			}
		}
	}

	/*
	 * Private methods
	 */
	_setState(state, key, value) {
		const newState = state;
		let root = newState;
		const keys = key ? key.split('.') : [];
		keys.forEach((_key, idx) => {
			if (idx < keys.length - 1) {
				root = root[_key] = root[_key] || {};
			} else {
				root[_key] = value;
			}
		});
		return newState;
	}

	_update(key, value) {
		this.dispatchCustomEvent(this.EVENTS.update, {
			key,
			value
		});
	}

	/*
	 * Helpers
	 */
	isStorageAvailable() {
		try {
			this.STORAGE.setItem('x', 'x');
			this.STORAGE.removeItem('x', 'x');

			this.STORAGE_PERSIST.setItem('x', 'x');
			this.STORAGE_PERSIST.removeItem('x', 'x');
			return true;
		} catch (e) {
			return false;
		}
	}
}

module.exports = new AppState();
