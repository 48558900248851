import EventEmitter from './EventEmitter';
import AppState from './AppState';

class AppStore extends EventEmitter {
    constructor() {
        super();

        this.EVENTS = {
            update: 'store::update',
        };

        this.KEYS = {
            filters: 'filters',
            user: 'user',
            savedLoginCreds: 'savedLoginCreds',
            fontSize: 'fontSize',
            activeStep: 'activeStep',
            popUpOpen: 'popUpOpen',
        };

        this.stateUpdateHandler = this.stateUpdateHandler.bind(this);

        this.setupEventListeners();
    }

    setupEventListeners() {
        AppState.addEventListener(
            AppState.EVENTS.update,
            this.stateUpdateHandler
        );
    }

    getKey(key) {
        return AppState.get(key);
    }

    /*
     * Handle filter changes
     */
    setFilter(filter) {
        const { name, type } = filter;
        const storeKey = this.KEYS.filters;
        const currentFilters = AppState.get(storeKey) || [];
        const filterIndex = currentFilters.findIndex(
            (filter) => filter.name === name
        );

        if (filterIndex >= 0) {
            switch (type) {
                case 'checkbox':
                    if (filter.value) {
                        currentFilters[filterIndex] = filter;
                    } else {
                        currentFilters.splice(filterIndex, 1);
                    }
                    break;

                default:
                    currentFilters[filterIndex] = filter;
                    break;
            }
        } else {
            currentFilters.push(filter);
        }

        AppState.set(storeKey, currentFilters, true);
    }

    removeFilter(filter) {
        const { name } = filter;

        const storeKey = this.KEYS.filters;
        const currentFilters = AppState.get(storeKey) || [];

        let position = currentFilters.findIndex(
            (filter) => filter.name === name
        );

        currentFilters.splice(position, 1);

        AppState.set(storeKey, currentFilters, true);
    }

    /*
     * Set new filter array
     */
    setFilters(filters) {
        const storeKey = this.KEYS.filters;

        AppState.set(storeKey, filters, true);
    }

    setFontSize(size) {
        AppState.set(this.KEYS.fontSize, size, true);
    }

    setUser(user) {
        AppState.set(this.KEYS.user, user, true);
    }

    saveCreds(creds) {
        AppState.set(this.KEYS.savedLoginCreds, creds, true);
    }

    setUserData(data) {
        AppState.set(this.KEYS.user['user_data'], data, true);
    }

    setActiveStep(number) {
        AppState.set(this.KEYS.activeStep, number, true);
    }

    setPopUpState(state) {
        AppState.set(this.KEYS.popUpOpen, state, true);
    }

    stateUpdateHandler({ detail }) {
        this.dispatchCustomEvent(this.EVENTS.update, detail);
    }
}

module.exports = new AppStore();
