import EventEmitter from './EventEmitter';
import { TweenLite } from 'gsap';

class ScrollManager extends EventEmitter {
	constructor() {
		super();

		this.EVENTS = {
			scroll: 'ScrollManager::scroll'
		};

		this.prevScrollPosX = 0;
		this.prevScrollPosY = 0;

		this.scrollHandler = this.scrollHandler.bind(this);
		this._emitScrollComplete = this._emitScrollComplete.bind(this);
		
		this.initialized();
	}

	initialized() {
		this.setupEventListeners();
	}

	setupEventListeners() {
		window.addEventListener('scroll', this.scrollHandler, { passive: true });
	}


	scrollTo(position, duration = 0.3) {
		this.scrollObject = {
			x: this.getCurrentScrollPosX(),
			y: this.getCurrentScrollPosY()
		};

		TweenLite.killTweensOf(this.scrollObject);
		TweenLite.to(this.scrollObject, duration, {y: position, ease: Quad.easeInOut, onUpdate: () => {
			window.scrollTo(this.scrollObject.x, this.scrollObject.y);
		}});
	}

	scrollToTop() {

		this.scrollTo(0);

	}
	

	getCurrentScrollPosY() {
		return document.documentElement.scrollTop || document.body.scrollTop || 0;
	}

	getCurrentScrollPosX() {
		return document.documentElement.scrollLeft || 0;
	}

	_emitScroll(completed = false) {
		const scrollPosX = this.getCurrentScrollPosX();
		const scrollPosY = this.getCurrentScrollPosY();

		this.dispatchCustomEvent(this.EVENTS.scroll, {
			x: scrollPosX,
			y: scrollPosY,
			completed
		});

		this.prevScrollPosX = scrollPosX;
		this.prevScrollPosY = scrollPosY;
	}

	_emitScrollComplete() {
		this._emitScroll(true);
	}

	scrollHandler() {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.timer = setTimeout(this._emitScrollComplete, 500);
		this._emitScroll();
	}

}

module.exports = new ScrollManager();