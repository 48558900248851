class EventEmitter {
	constructor() {
		// Create a delegate DOM element so we can emit events from this class
		const delegate = document.createDocumentFragment();
		['addEventListener', 'dispatchEvent', 'removeEventListener'].forEach(method => {
			this[method] = (...xs) => delegate[method](...xs);
		});
	}

	dispatchCustomEvent(eventName, detail) {
		const event = document.createEvent('CustomEvent');
		event.initCustomEvent(eventName, false, false, detail);
		this.dispatchEvent(event);
	}
}

module.exports = EventEmitter;
